<template>
  <div class="relative z-100">
    <div class="relative group">
      <button
        v-if="loggedIn === false"
        class="lg:flex lg:flex-row block w-full font-bold px-4 py-4 text-base text-left"
        @click="onShowLogin()"
      >
        <span>{{ $t('LOGIN') }}</span>
      </button>

      <a
        v-else
        class="lg:flex lg:flex-row block w-full font-bold px-4 py-4 text-base text-left"
        :href="'/account'"
        :title="$t('ACCOUNT')"
      >
        <span>{{ $t('ACCOUNT') }}</span>
      </a>
    </div>

    <div
      v-if="loggedIn === false"
      class="relative group"
    >
      <a
        class="block w-full font-bold px-4 py-4 text-base text-left"
        :href="'/registrieren'"
        :title="$t('REGISTER')"
      >
        <span>{{ $t('REGISTER') }}</span>
      </a>
    </div>
    <login
      v-if="showLogin === true"
      :show-login="showLogin"
      :redirect-after-login="true"
      @showLogin="onShowLogin()"
    />
  </div>
</template>
  
<script>
import { defineAsyncComponent } from "vue";
const Login = defineAsyncComponent(() =>
  import("./Login" /* webpackChunkName: "login" */)
);
  
export default {
  name: "MobileNavigationLogin",
  components: {
    Login
  },
  data() {
    return {
      loggedIn: false,
      showLogin: false
    };
  },
  mounted() {
    this.intervalid1 = setInterval(function(){
      if (typeof window.localStorage.getItem('logged-in') === 'undefined' || window.localStorage.getItem('logged-in') === null) {
        this.loggedIn =  false;
      }
      this.loggedIn = (window.localStorage.getItem('logged-in') === 'true');
    }.bind(this), 500);
  },
  methods: {
    onShowLogin() {
      this.showMobileMenu = false;
      this.open = false;
      this.showLogin = !this.showLogin;
    },
  }
}
</script>